import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/martijn/workspace/moncau/src/components/layout.js";
import { graphql } from "gatsby";
import { Jumbotron, Button } from "reactstrap";
import BackgroundImage from 'gatsby-background-image';
export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    coverImg:     file(relativePath: { eq: "cover.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    wayOfWorkImg:     file(relativePath: { eq: "home/how_we_work.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    separator1Img:     file(relativePath: { eq: "moncau/IMG_7298.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    separator2Img:     file(relativePath: { eq: "moncau/IMG_7343.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    separator3Img:     file(relativePath: { eq: "moncau/IMG_7343.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    product1Img:     file(relativePath: { eq: "moncau/IMG_7280.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    product2Img:     file(relativePath: { eq: "moncau/IMG_7308.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    product3Img:     file(relativePath: { eq: "moncau/IMG_7311.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BackgroundSection = makeShortcode("BackgroundSection");
const Container = makeShortcode("Container");
const ButtonLink = makeShortcode("ButtonLink");
const Section = makeShortcode("Section");
const Row = makeShortcode("Row");
const Col = makeShortcode("Col");
const Img = makeShortcode("Img");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <BackgroundSection backgroundFluid={props.data.coverImg.childImageSharp.fluid} className={"darkbg landing"} style={{
      minHeight: "60vh"
    }} mdxType="BackgroundSection">
      <Jumbotron className={"transparent darkbg"} mdxType="Jumbotron">
        <Container className={"light"} mdxType="Container">
          <h1>{`MONCAU`}</h1>
          <p>{`On la història, el record i la qualitat es troben. Vols tastar el pa artesà de sempre? cruixent per fora i esponjós per dins?`}</p>
          <ButtonLink to="/tenda/" mdxType="ButtonLink">Vull tastar-lo</ButtonLink>
        </Container>
      </Jumbotron>
    </BackgroundSection>
    <Section className="light" mdxType="Section">
      <Container mdxType="Container">
        <Row mdxType="Row">
          <Col md={6} mdxType="Col">
            <h3>{`La nostra elavoració`}</h3>
            <hr></hr>
            <h1>{`Perquè fem pa artesà?`}</h1>
            <p>{`El pa és un dels aliments bàsics en la nostra dieta Mediterrania. Els nostres pans estan elaborats amb massa mare. `}</p>
            <p>{`Treballem amb massa mare de fermentació lenta. Un procés que requereix temps i constància però que el resultat final recompensa els nostres esforços.`}</p>
            <p>{`El pa artesà es caracteritza per tenir una crosta cruixent i compacta mentre que l'interior del pa és esponjós. Els pans elaborats amb massa mare tenen una durabilitat més llarga i són més acceptats per el nostre organisme durant la digestió.`}</p>
            <ButtonLink to="/sobre-nosaltres/" mdxType="ButtonLink">Més informació</ButtonLink>
          </Col>
          <Col md={6} mdxType="Col">
            <Img fluid={props.data.wayOfWorkImg.childImageSharp.fluid} mdxType="Img" />
          </Col>
        </Row></Container></Section>
    <BackgroundSection backgroundFluid={props.data.separator1Img.childImageSharp.fluid} className={"fixed separator shadow section-dark"} style={{
      minHeight: "30vh"
    }} mdxType="BackgroundSection" />
  
    <Section className="products" mdxType="Section">
      <Container mdxType="Container">
        <Row mdxType="Row">
  <Col md={4} className="text" mdxType="Col">
            <h3>{`Artesanal`}</h3>
            <h1>{`Pa`}</h1>
            <p>{`Pa amb cor. `}</p>
            <ButtonLink to="/assortiment/#pa" mdxType="ButtonLink">Ver productos</ButtonLink>
  </Col>
  <Col md={4} className="img" mdxType="Col"><BackgroundImage fluid={props.data.product1Img.childImageSharp.fluid} mdxType="BackgroundImage" /></Col>
  <Col md={4} className="text" mdxType="Col">
            <h3>{`Qualitat`}</h3>
            <h1>{`Pastisseria`}</h1>
            <p>{`Pastissos per totes les cel.lebracions i dies que fem especials. `}</p>
            <ButtonLink to="/assortiment/#pastisseria" mdxType="ButtonLink">Ver productos</ButtonLink>
  </Col>
  <Col md={4} className="img" mdxType="Col"><BackgroundImage fluid={props.data.product2Img.childImageSharp.fluid} mdxType="BackgroundImage" /></Col>
  <Col md={4} className="text" mdxType="Col">
            <h3>{`Innovadora`}</h3>
            <h1>{`Reposteria`}</h1>
            <p>{`Dóna't un caprici i tria, dolç o salat?`}</p>
            <ButtonLink to="/assortiment/#reposteria" mdxType="ButtonLink">Ver productos</ButtonLink>
  </Col>
  <Col md={4} className="img" mdxType="Col"><BackgroundImage fluid={props.data.product3Img.childImageSharp.fluid} mdxType="BackgroundImage" /></Col>
        </Row>
      </Container>
    </Section>
    <Section className="dark" mdxType="Section">
      <Container mdxType="Container">
        <h1>{`Certificats`}</h1>
        <p>{`Ens han otorgat diferents certificats tant per la nostra història com la qualitat dels nostres productes.`}</p>
        <ButtonLink to="/certificats/" mdxType="ButtonLink">Contacte</ButtonLink>
      </Container>
    </Section>
    <BackgroundSection backgroundFluid={props.data.separator3Img.childImageSharp.fluid} className={"fixed separator"} style={{
      minHeight: "30vh"
    }} mdxType="BackgroundSection" />
    <Section className="center" mdxType="Section">
      <Container mdxType="Container">
        <h3>{`Truca'ns`}</h3>
        <hr></hr>
        <h1>{`Contacte`}</h1>
        <p>{`Ens trobareu al centre de L'Ametlla del Vallès al Passeig Torregassa, 29.`}</p>
        <ButtonLink to="/contacte/" mdxType="ButtonLink">Contacte</ButtonLink>
      </Container>
    </Section>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      